<script>
import VxKonamiButtons from "./VxKonamiButtons";

export default {
  cheatcode: [
    "up",
    "up",
    "down",
    "down",
    "left",
    "right",
    "left",
    "right",
    "b",
    "a",
    "start",
  ],
  components: {
    VxKonamiButtons,
  },
  data: () => ({
    value: [],
  }),
  computed: {
    // the next expected value
    expected() {
      return this.$options.cheatcode[this.value.length];
    },
    unlocked() {
      // it has to be the same length
      if (this.value.length !== this.$options.cheatcode.length) return false;

      // this should be true...
      for (var i = 0; i < this.value.length; i++) {
        if (this.value[i] !== this.$options.cheatcode[i]) return false;
      }
      return true;
    },
    // show when needed, hide when unlocked
    showButtons() {
      if (this.value.length < 8) return false;
      return !this.unlocked;
    },
  },
  created() {
    window.addEventListener("keydown", this.keyDown);
  },
  destroyed() {
    window.removeEventListener("keydown", this.keyDown);
  },
  methods: {
    keyDown(event) {
      switch (event.key) {
        case "ArrowLeft":
          this.press("left");
          break;
        case "ArrowRight":
          this.press("right");
          break;
        case "ArrowUp":
          this.press("up");
          break;
        case "ArrowDown":
          this.press("down");
          break;
        default:
          this.press(event.key);
      }
    },
    press(val) {
      if (this.unlocked) return true;
      console.log("press", val);
      // if the expected key was entered, add it and return
      if (val === this.expected) return this.value.push(val);

      // are they starting over?
      this.value = val === this.$options.cheatcode[0] ? [val] : [];
    },
  },
};
</script>

<template>
  <div
    v-touch="{
      left: () => press('left'),
      right: () => press('right'),
      up: () => press('up'),
      down: () => press('down'),
    }"
    class="vx-konami fill-height"
    @keydown.native="keyDown"
  >
    <VxKonamiButtons v-model="showButtons" @click="press" />
    <template v-if="unlocked">
      <slot name="easterEgg">EASTER EGG SLOT</slot>
    </template>
    <template v-else>
      <slot></slot>
    </template>
  </div>
</template>
