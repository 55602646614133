<script>
import VxDialogView from "@/components/vx/VxDialogView";
import VxKonami from "@/components/vx/VxKonami";
import AboutRai from "@/components/AboutRai";
export default {
  components: {
    VxDialogView,
    VxKonami,
    AboutRai,
  },
  data: () => ({
    subdomain: null,
  }),
  created() {
    this.subdomain = window.location.host.split(".")[1]
      ? window.location.host.split(".")[0]
      : "";
  },
  methods: {
    go() {
      window.location.href = `https://${this.subdomain}.resaleai.com`;
    },
  },
};
</script>

<template>
  <VxDialogView title="About ResaleAI">
    <VxKonami>
      <AboutRai />
      <template #easterEgg>
        <v-card>
          <v-card-text>
            <v-text-field v-model="subdomain" suffix=".resaleai.com">
              <template v-slot:append-outer>
                <v-btn @click="go">go</v-btn>
              </template>
            </v-text-field>
          </v-card-text>
        </v-card>
      </template>
    </VxKonami>
  </VxDialogView>
</template>
