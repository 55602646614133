<script>
export default {
  props: {
    value: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    dialog: {
      get() {
        return this.value;
      },
      set(val) {
        this.$emit("input", val);
      },
    },
  },
};
</script>
<template>
  <v-dialog v-model="dialog" hide-overlay persistent width="300">
    <v-card color="#B0BEC5" class="rounded-xl">
      <v-card-actions>
        <v-btn rounded small dark>Select</v-btn>
        <v-btn rounded small dark @click="$emit('click', 'start')">Start</v-btn>
        <v-spacer />
        <v-btn fab large color="red" @click="$emit('click', 'a')">A</v-btn>
        <v-btn fab large color="red" @click="$emit('click', 'b')">B</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
