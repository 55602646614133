<template>
  <v-card flat>
    <v-card-text>
      <v-list>
        <v-list-item two-line>
          <v-list-item-content>
            <v-list-item-title v-if="$raiPos">
              ResaleAI POS
              <v-btn color="primary" text :to="{ name: 'SoftwareUpdateView' }">
                Manage Updates
              </v-btn>
            </v-list-item-title>
            <v-list-item-title v-else>Version</v-list-item-title>
            <v-list-item-subtitle>{{ version }}</v-list-item-subtitle>
          </v-list-item-content>
          <v-list-item-action> </v-list-item-action>
        </v-list-item>
        <v-list-item>
          <v-list-item-content two-line>
            <v-list-item-title>Copyright</v-list-item-title>
            <v-list-item-subtitle>&copy; 2015 ResaleAI</v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>
        <v-list-item>
          <v-list-item-content @click="$sounds.play('beep')">
            <v-list-item-title>Test audio</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </v-card-text>
    <v-card-text v-if="$raiPos">
      <PosInfo />
    </v-card-text>
    <v-card-actions v-if="$raiPos">
      <v-btn text @click.native="printTestPage">Print test page</v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import { hasSnackbarAccess } from "@/mixins/ui";
export default {
  components: {
    PosInfo: () => import("@/components/pos/PosInfo"),
  },
  mixins: [hasSnackbarAccess],
  props: {
    aboutDialog: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      version: process.env.VUE_APP_VERSION,
      dialog: true,
    };
  },
  methods: {
    close() {
      this.$emit("input", false);
      this.$emit("update:aboutDialog", false);
      if (this.$route.path.match(/about/)) return this.$router.go(-1);
    },
    printTestPage() {
      try {
        this.$raiPos.printTestPage();
      } catch (error) {
        this.showSnackbar({ text: `Error printing test page: ${error}` });
      }
    },
  },
};
</script>
